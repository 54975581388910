import type { DiscountVoucher } from '~/models';

import { useNuxtApp } from '#app';
import type { Filter } from '~/services/discount';

const useDiscountStore = defineStore('customer-discount', () => {
  const { $http } = useNuxtApp();

  const fixed = async (filter?: Filter): Promise<number> => {
    return $http.discount.fixed(filter);
  };

  const voucher = async (filter?: Filter): Promise<DiscountVoucher> => {
    return $http.discount.voucher(filter);
  };

  const order = async (filter?: Filter): Promise<number> => {
    return $http.discount.order(filter);
  };

  return {
    fixed,
    voucher,
    order
  };
});

export { useDiscountStore };
